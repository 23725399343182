const config = {
    network: 'mainnet',
    dailydropmode: 'mint',
    dailydrop: {
        reserve: {
            description: "RESERVE - The box includes 3 uniquely crafted artwork by premium artists across the world. There will be a total of 40 copies available and will be minted in the ERC-1155 protocol and will be revealed on 14th April, 2022!"
        },
        mint: {
            description: "MINT - The box includes 3 uniquely crafted artwork by premium artists across the world. There will be a total of 40 copies available and will be minted in the ERC-1155 protocol and will be revealed on 14th April, 2022!"
        }
    },
    testnet: {
        chainId: "0x61", // BNB
        chainIdEth: "0x5",
        contract_css: "0x5B76385aeA60F45991B72E0CBbd4c75597024699",
        contract_maalaamal: "0xeA5f48e41C6cBB9cc3c4912497f8588943387b8B",
        contract_maal: "0x1D45e5374B4a642F2619958a5391c15c63A37258",
        contract_dailydrop_mint: "0x5BFeBaAF138965B458595BF3A4d393543048DAeD",
        contract_dailydrop_reserve: "0x222CCDd90a54C69721c51c28c7722116E2e4CAB2",
        contract_eth_migration_mint: "0x86104E71E7FE6E0B3aC4D5078c5E43f2f6Dc8581"
    },
    mainnet: {
        chainId: "0x38", // BNB
        chainIdEth: "0x1",
        contract_css: "0xB382Fcd0263f4e338437F3e9DdB445063A818D6c",
        contract_maalaamal: "0xFE692ADD44b90889D5cF7397e179E6240B7799c8",
        contract_maal: "0x21A14970Cad64d054b9E3b54299493dB762f9f1f",
        contract_dailydrop_mint: "0x5BFeBaAF138965B458595BF3A4d393543048DAeD",
        contract_dailydrop_reserve: "0xb690480d6977A0de404B643059762cf4F96dB6D0",
        contract_eth_migration_mint: "0x86104E71E7FE6E0B3aC4D5078c5E43f2f6Dc8581"

    },
    cards: {
        links: {
            silver: "https://res.cloudinary.com/cryptosauga/video/upload/v1645491687/malamaal/silver.mp4",
            gold: "https://res.cloudinary.com/cryptosauga/video/upload/v1645491687/malamaal/gold.mp4",
            rose: "https://res.cloudinary.com/cryptosauga/video/upload/v1645491687/malamaal/rose.mp4",
            black: "https://res.cloudinary.com/cryptosauga/video/upload/v1645491687/malamaal/black.mp4"
        },
        manufacturing: {
            silver: "https://res.cloudinary.com/cryptosauga/video/upload/v1645491687/malamaal/silver-m.mp4",
            gold: "https://res.cloudinary.com/cryptosauga/video/upload/v1645491687/malamaal/gold-m.mp4",
            rose: "https://res.cloudinary.com/cryptosauga/video/upload/v1645491687/malamaal/rose-m.mp4",
            black: "https://res.cloudinary.com/cryptosauga/video/upload/v1645491687/malamaal/black-m.mp4"
        }
    }
}

export default config;