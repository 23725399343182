import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { Image } from 'antd';

//IMPORT DYNAMIC STYLED COMPONENT
import { StyledHeader } from '../Styles';
import { useWeb3ExecuteFunction } from 'react-moralis';
import { useMoralis, useChain } from "react-moralis";
import CSSC_ABI from "../../abi/abi_css.json";
import config from '../../config/config';
import { Spin } from "antd";
import useMoralisAPI from "../../hooks/useMoralisAPI";
import useToaster from '../../components/components/Toast';


const Outer = styled.div`
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  overflow: hidden;
  border-radius: 8px;
`;

const Button = styled.button`
  width: 200px !important;
`;

//SWITCH VARIABLE FOR PAGE STYLE
const theme = 'GREY'; //LIGHT, GREY, RETRO

const MyMysteryBox = () => {
    const { Moralis, isAuthenticated, account } = useMoralis();
    const { switchNetwork } = useChain();

    const { toast } = useToaster();
    const { mysteryBox, isBinanceNetwork, isEthNetwork } = useMoralisAPI();
    const { t } = useTranslation();
    const [myMysteryBox, setMyMysteryBox] = useState([]);

    useEffect(() => {
        if (mysteryBox.data && mysteryBox.data.length > 0) {
            const data = JSON.parse(JSON.stringify(mysteryBox.data))
            setMyMysteryBox(data);
        }
    }, [mysteryBox]);


    return (
        <div className="greyscheme">
            <StyledHeader theme={theme} />
            <section className="container jumbotron no-bg">
                <div>
                    <div>
                        <div className="de-flex justify-content-center mb30">
                            <h3>{t('my_mystery_box')}</h3>
                        </div>
                    </div>
                </div>

                {
                    isBinanceNetwork &&
                    <>
                        <div className="de-flex centered mb30">
                            {t('my_mystery_switch_network_msg')}
                        </div>

                        <div className="connect-wal de-flex centered">
                            <a>
                                <span onClick={() => switchNetwork(config[config.network].chainIdEth)}>
                                    {'Switch to Ethereum Network'}
                                </span>
                            </a>
                        </div>
                    </>

                }

                {
                    myMysteryBox.isLoading &&
                    <div style={{ height: '50vh', flexDirection: 'column', animation: "zoom-in-zoom-out 2s ease infinite" }} className="centered">
                        <Spin size="large" />
                        <div style={{ color: "#ffff" }}>
                            Loading...
                        </div>
                    </div>
                }

                {
                    isAuthenticated && account && !mysteryBox.isLoading && myMysteryBox.length > 0 &&
                    <>
                        <div className="row">
                            {
                                isEthNetwork && myMysteryBox.map((nft, i) => {
                                    return <div key={i}
                                        className="d-item col-lg-4 col-md-6 col-sm-6 col-xs-12 mb-4"
                                        style={{ maxWidth: 300 }}>
                                        <div className="nft__item m-0">
                                            <h3 style={{ marginBottom: '0' }}>Khazana #{nft.token_id}</h3>
                                            <div className="nft__item_wrap">
                                                <Outer>
                                                    <Image src={nft.image} id="vid" width="100%" height="250" preview={false} />
                                                </Outer>
                                            </div>
                                            <div className="nft__item_info">

                                            </div>
                                        </div>
                                    </div>

                                })
                            }
                        </div>
                    </>
                }
            </section>
        </div>
    );
}
export default MyMysteryBox;
