import React, { createContext, useContext, useEffect, useState } from 'react';
import CSS_ABI from '../abi/abi_css.json';
import MAALA_MAAL_ABI from '../abi/abi_maalamaal.json';
import MAAL_ABI from '../abi/abi_maal.json';
import DAILY_DROP_MINT_ABI from '../abi/abi_dailydrop_mint.json';
import DAILY_DROP_RESERVE_ABI from '../abi/abi_dailydrop_reserve.json';
import { useMoralis, useWeb3ExecuteFunction } from 'react-moralis';
import config from '../config/config';
import { useNFTBalance } from './useNftBalance';

const MoralisAPIContext = createContext();

export function MoralisAPIProvider({ children }) {
  const mixpanel = useMoralisAPIPorvider();
  return (
    <MoralisAPIContext.Provider value={mixpanel}>
      {children}
    </MoralisAPIContext.Provider>
  );
}

function useMoralisAPIPorvider() {
  const [contractABI, setCssContractABI] = useState(null); //Smart Contract ABI here
  const [dailyDropMintContractABI, setDailyDropMintContractABI] = useState(null); //Smart Contract ABI here
  const [dailyDropReserveContractABI, setDailyDropReserveContractABI] = useState(null); //Smart Contract ABI here
  const [malaMaalContractABI, setMalaMaalContractABI] = useState(null); //Smart Contract ABI here
  const [maalContractABI, setMaalContractABI] = useState(null); //Smart Contract ABI here
  const [tokenIdOptions, setTokenIdOptions] = useState(null);
  const [maalBalance, setMaalBalance] = useState(0);
  const [tokenIds, setTokenIds] = useState(0);
  const [isBinanceNetwork, setIsBinanceNetwork] = useState(false);
  const [isEthNetwork, setIsEthNetwork] = useState(false);
  const { Moralis, account, enableWeb3, isAuthenticated, chainId, isWeb3Enabled, isWeb3EnableLoading } = useMoralis();
  const { NFTBalance: NFTBalanceM_MMC, isLoading: isLoadingMalaMaalCard, getNFTBalance: getNFTBalance_MMC } = useNFTBalance({ token_address: config[config.network].contract_maalaamal, address: account }, true);
  const { NFTBalance: NFTBalanceM_CSSC, isLoading: isLoadingCSSC, getNFTBalance: getNFTBalance_CSSC } = useNFTBalance({ token_address: config[config.network].contract_css, address: account });
  const { NFTBalance: NFTBalanceM_Mystery, isLoading: isLoadingMystery, getNFTBalance: getNFTBalance_Mystery } = useNFTBalance({ token_address: config[config.network].contract_dailydrop_mint, address: account });
  const cardMapper = {
    'silver': 50,
    'gold': 100,
    'rose': 250,
    'black': 500
  };
  const [isApproved, setIsApproved] = useState(false);
  const {
    data: successAllowanceMaal,
    error: errorAllowanceMaal,
    fetch: allowanceMaal,
    isFetching: isFetchingAllowanceMaal,
    isLoading,
  } = useWeb3ExecuteFunction({
    abi: maalContractABI,
    contractAddress: config[config.network].contract_maal,
    functionName: 'allowance',
    params: {
      owner: account,
      spender: config[config.network].contract_maalaamal,
    },
  });

  const {
    data: successAllowanceReserve,
    error: errorAllowanceReserve,
    fetch: allowanceReserve,
    isFetching: isFetchingAllowanceReserve,
    isLoadingReserveAllowance,
  } = useWeb3ExecuteFunction({
    abi: maalContractABI,
    contractAddress: config[config.network].contract_maal,
    functionName: 'allowance',
    params: {
      owner: account,
      spender: config[config.network].contract_dailydrop_reserve,
    },
  });

  const {
    data: succMaalBal,
    error: errMaalBal,
    fetch: getMaalBalance,
    isFetching: isFetchingMaalBal,
    isLoading: isLoadMaalBal,
  } = useWeb3ExecuteFunction({
    abi: maalContractABI,
    contractAddress: config[config.network].contract_maal,
    functionName: 'balanceOf',
    params: {
      account: account,
    },
  });

  const {
    data: succGetClaimable,
    error: errGetClaimable,
    fetch: getClaimableReward,
    isFetching: isFetchingGetClaimable,
    isLoading: isLoadGetClaimable,
  } = useWeb3ExecuteFunction({
    abi: maalContractABI,
    contractAddress: config[config.network].contract_maal,
    functionName: 'getClaimableReward',
    params: {
      _tokenIds: tokenIds,
    },
  });

  // cSSC
  const {
    data: isMintWithTknActive,
    error: errIsMintWithTknActive,
    fetch: checkIfMintWithTknActive,
    isFetching: fetchingIsMintWithTknActive,
    isLoading: loadingIsMintWithTknActive,
  } = useWeb3ExecuteFunction({
    abi: contractABI,
    contractAddress: config[config.network].contract_css,
    functionName: 'isMintWithTknActive'
  });


  const {
    data: successTokensOfOwner,
    error: errorTokensOfOwner,
    fetch: getTokensOfOwner,
    isFetching: isFetchingTokensOfOwner,
    isLoading: isLoadTokensOfOwner,
  } = useWeb3ExecuteFunction({
    abi: malaMaalContractABI,
    contractAddress: config[config.network].contract_css,
    functionName: 'tokensOfOwner',
    params: {
      _owner: account,
    },
  });

  const {
    data: successRemainForResrv,
    fetch: remainingForReserve,
  } = useWeb3ExecuteFunction({
    abi: DAILY_DROP_RESERVE_ABI,
    contractAddress: config[config.network].contract_dailydrop_reserve,
    functionName: 'remainingForReserve'
  });

  // check if mint star is active
  const {
    data: successIsMalaMintActive,
    error: errorIsMalaMint,
    fetch: getIsMalMintActive,
    isFetching: isFetchingMalaMintActive,
    isLoading: isLoadMalaMintActive
  } = useWeb3ExecuteFunction({
    abi: malaMaalContractABI,
    contractAddress: config[config.network].contract_maalaamal,
    functionName: 'isActive'
  });

  // check if mint malamaal active
  const {
    data: successIsRedeemActive,
    error: errorIsRedeemActive,
    fetch: getIsRedeemActive,
    isFetching: isFetchingRedeemActive,
    isLoading: isLoadRedeemActive
  } = useWeb3ExecuteFunction({
    abi: malaMaalContractABI,
    contractAddress: config[config.network].contract_maalaamal,
    functionName: 'isRedeemActive'
  });

  // daily drop mint
  const {
    data: successMintActive,
    error: errorMintActive,
    fetch: isDailyDropMintActive,
    isFetching: fetchMintActive,
    isLoading: loadMintActive,
  } = useWeb3ExecuteFunction({
    abi: dailyDropMintContractABI,
    contractAddress: config[config.network].contract_dailydrop_mint,
    functionName: 'isActive'

  });

  const {
    data: successTotalMinted,
    error: errorTotalMinted,
    fetch: getTotalMinted,
    isFetching: fetchTotalMinted,
    isLoading: loadTotalMinted,
  } = useWeb3ExecuteFunction({
    abi: dailyDropMintContractABI,
    contractAddress: config[config.network].contract_dailydrop_mint,
    functionName: 'getTotalMinted',
    params: {
      user: account
    }
  });

  // daily drop reserve
  const {
    data: successReserveActive,
    error: errorReserveActive,
    fetch: isDailyDropReserveActive,
    isFetching: fetchReserveActive,
    isLoading: loadReserveActive,
  } = useWeb3ExecuteFunction({
    abi: dailyDropReserveContractABI,
    contractAddress: config[config.network].contract_dailydrop_reserve,
    functionName: 'isActive'
  });

  //daily drop whitelist

  const {
    data: succesGetWhitelist,
    error: errorGetWhitelist,
    fetch: getWhitelistForDailyDrop,
    isFetching: isFetchingWhitelist,
    isLoading: isLoadingWhitelist,
  } = useWeb3ExecuteFunction({
    abi: dailyDropReserveContractABI,
    contractAddress: config[config.network].contract_dailydrop_reserve,
    functionName: 'getWhitelistedAddresses'
  });



  useEffect(() => {
    const connectorId = window.localStorage.getItem('connectorId');
    if (isAuthenticated && !isWeb3Enabled && !isWeb3EnableLoading)
      enableWeb3({ provider: connectorId });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, isWeb3Enabled]);

  useEffect(() => {
    if (chainId) {
      setIsBinanceNetwork(chainId === config[config.network].chainId);
      setIsEthNetwork(chainId === config[config.network].chainIdEth);
    }
  }, [chainId]);

  useEffect(() => {
    setMalaMaalContractABI(MAALA_MAAL_ABI);
    setCssContractABI(CSS_ABI);
    setMaalContractABI(MAAL_ABI);
    setDailyDropMintContractABI(DAILY_DROP_MINT_ABI);
    setDailyDropReserveContractABI(DAILY_DROP_RESERVE_ABI);
    allowanceMaal();
    allowanceReserve();
    getMaalBalance();
    getIsRedeemActive();
    getIsMalMintActive();
    checkIfMintWithTknActive();
    isDailyDropReserveActive();
    isDailyDropMintActive();
    getWhitelistForDailyDrop();
    getNFTBalance_Mystery();
    getTotalMinted();
    remainingForReserve();
    setIsBinanceNetwork(chainId === config[config.network].chainId);
  }, [isWeb3Enabled, chainId, account]);


  useEffect(() => {
    getTokensOfOwner();
  }, [account]);


  useEffect(() => {
    if (successAllowanceMaal) {
      const allwance = Moralis.Units.FromWei(successAllowanceMaal);
      if (allwance > 0) {
        setIsApproved(true);
      }
    }
  }, [successAllowanceMaal]);

  useEffect(() => {
    if (succMaalBal) {
      const balance = Moralis.Units.FromWei(succMaalBal);
      const balWith2Decimals = balance.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0]
      setMaalBalance(balWith2Decimals);
    }
  }, [succMaalBal]);

  useEffect(() => {
    if (successTokensOfOwner) {
      let options = [];
      let tokenIds = [];
      successTokensOfOwner.forEach((bigNumber) => {
        const tokenId = Moralis.Units.FromWei(bigNumber, 0);
        options.push({
          value: tokenId,
          label: tokenId,
        });
        tokenIds.push(tokenId);
      });
      setTokenIds(tokenIds);
      // call getclaimablerewards

      setTokenIdOptions(options);
    }
  }, [successTokensOfOwner]);

  useEffect(() => {
    if (tokenIds && tokenIds.length > 0) {
      getClaimableReward(tokenIds);
    }
  }, [tokenIds]);


  return {
    contractABI,
    malaMaalContractABI,
    maalContractABI,
    dailyDropMintContractABI,
    dailyDropReserveContractABI,
    isBinanceNetwork: isBinanceNetwork,
    isEthNetwork: isEthNetwork,
    dailydrop: {
      isReserveActive: successReserveActive,
      isLoadReserveActive: loadReserveActive,
      isLoadMintActive: loadMintActive,
      isMintActive: successMintActive,
      whitelist: succesGetWhitelist,
      remainingForReserve: successRemainForResrv
    },
    isActive: {
      isFetchingMalMintActive: isFetchingMalaMintActive,
      isRedeemActive: successIsRedeemActive,
      isMalaMintActive: successIsMalaMintActive,
      isMintWithTokenActive: isMintWithTknActive
    },
    cardMapper: cardMapper,
    myStars: {
      data: NFTBalanceM_CSSC,
      isLoading: isLoadingCSSC
    },
    mysteryBox: {
      data: NFTBalanceM_Mystery,
      isLoading: isLoadingMystery,
      totalMinted: successTotalMinted,
      allowance: successAllowanceReserve
    },
    rewards: {
      data: succGetClaimable,
      isLoading: isLoadGetClaimable
    },
    malaMaal: {
      data: NFTBalanceM_MMC,
      isLoading: isLoadingMalaMaalCard
    },
    allowance: {
      data: successAllowanceMaal,
      error: errorAllowanceMaal,
      isLoading: isLoading,
      isFetching: isFetchingAllowanceMaal,
    },
    balanceOf: {
      data: succMaalBal,
      error: errMaalBal,
      isLoading: isLoadMaalBal,
      isFetching: isFetchingMaalBal,
    },
    tokensOfOwner: {
      data: successTokensOfOwner,
      error: errorTokensOfOwner,
      isLoading: isLoadTokensOfOwner,
      isFetching: isFetchingTokensOfOwner,
    },
    isApproved,
    maalBalance,
    getMaalBalance,
    getTokensOfOwner,
    getMalaMaalNFTs: getNFTBalance_MMC,
    getMyStars: getNFTBalance_CSSC,
    getMaalAllowance: allowanceMaal,
    getMaalAllowanceReserve: allowanceReserve,
    getClaimableReward,
    getWhitelistForDailyDrop,
    getTotalMinted,
    tokenIdOptions,
  };
}

const useMoralisAPI = () => useContext(MoralisAPIContext);

export default useMoralisAPI;
