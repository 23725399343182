import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import Select from '../components/Select';
import { StyledHeader } from '../Styles';
import { useMoralis, useWeb3ExecuteFunction, useChain } from 'react-moralis';
import useMoralisAPI from '../../hooks/useMoralisAPI';
import { createOptions } from '../../utils';
import { Image } from 'antd';
import config from '../../config/config';
import useToaster from "../components/Toast";

import { Spin, Button } from 'antd';
import { toChecksumAddress } from 'ethereum-checksum-address';

//SWITCH VARIABLE FOR PAGE STYLE
const theme = 'GREY'; //LIGHT, GREY, RETRO

const reservePrice = 100;
const mintPrice = 0.25;

const MysteryBoxDrop = () => {
    const { account, Moralis } = useMoralis();
    const { switchNetwork } = useChain();
    const { dailyDropMintContractABI, dailyDropReserveContractABI, dailydrop, maalContractABI, getTotalMinted,
        isBinanceNetwork, isEthNetwork, getWhitelistForDailyDrop, mysteryBox, getMaalAllowanceReserve, getMaalBalance } = useMoralisAPI();

    const { t } = useTranslation();
    const [isMinting, setIsMinting] = useState(false);
    const [totalMinted, setTotalMinted] = useState(0);
    const [isReserving, setIsReserving] = useState(false);
    const [isApproving, setIsApproving] = useState(false);
    const [allowanceNumber, setAllowanceNumber] = useState(false);
    const [isWhitelisted, setIsWhitelisted] = useState(false);
    const [hasValidMerkleProof, setHasValidMerkleProof] = useState(false);
    const [remainingForReserve, setRemainingForReserve] = useState(1);
    const [isMysteryReserveActive, setIsMysteryReserveActive] = useState(false);
    const [isMysteryMintActive, setIsMysteryMintActive] = useState(false);
    const [merkleProof, setMerkleProof] = useState(false);
    const { toast } = useToaster();

    const {
        data: successMint,
        error: errorMint,
        fetch: mintMerkel,
        isFetching: fetchMint,
        isLoading: loadMint,
    } = useWeb3ExecuteFunction({
        abi: dailyDropMintContractABI,
        contractAddress: config[config.network].contract_dailydrop_mint,
        functionName: 'mint',
        params: {
            _merkleProof: merkleProof
        },
        msgValue: Moralis.Units.ETH("0")
    });

    const {
        data: successReserve,
        error: errorReserve,
        fetch: reserveSpot,
        isFetching: isFetchReserve,
        isLoading: isLoadReserve,
    } = useWeb3ExecuteFunction({
        abi: dailyDropReserveContractABI,
        contractAddress: config[config.network].contract_dailydrop_reserve,
        functionName: 'reserve'
    });

    const { data: successAproveMaal, error: errorApproveMaal, fetch: approveMaal,
        isLoading: isLoadingApprove } = useWeb3ExecuteFunction({
            abi: maalContractABI,
            contractAddress: config[config.network].contract_maal,
            functionName: "approve",
            params: {
                spender: config[config.network].contract_dailydrop_reserve,
                // amount: "0"
                amount: "1000000000000000000000000000"
            }
        });

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(async () => {
        if (account) {
            const Proof = Moralis.Object.extend("KhazanaProof");
            const query = new Moralis.Query(Proof);
            const checksumAdd = toChecksumAddress(account)
            query.equalTo("address", checksumAdd);
            const proofs = await query.find();

            if (proofs && proofs.length > 0) {
                setHasValidMerkleProof(true);
                const lastProofSubmitted = proofs[proofs.length - 1];
                setMerkleProof([lastProofSubmitted.attributes.value]);
            } else {
                setHasValidMerkleProof(false);
            }
        }
    }, [account]);

    useEffect(() => {
        console.log(errorMint);
        if (errorMint && errorMint.data && errorMint.data.message.includes('Address already claimed')) {
            toast('error', 'You already minted!');
        }
    }, [errorMint]);

    useEffect(() => {
        console.log(errorReserve);
        if (errorReserve && errorReserve.data && errorReserve.data.message.includes('You can only reserve 1 spot')) {
            toast('error', 'You already reserved!');
            setIsReserving(false);
        } else if (errorReserve && errorReserve.data && errorReserve.data.message.includes('Time up')) {
            toast('error', 'Time up! We are not allowing further reservations!');
            setIsReserving(false);
        } else if (errorReserve && errorReserve.message.includes('User denied transaction signature')) {
            toast('error', t('user_denied_txn'));
            setIsReserving(false);
        } else if (errorReserve && errorReserve.data && errorReserve.data.message.includes('Sold Out')) {
            toast('error', t('sold_out'));
            setIsReserving(false);
        }
    }, [errorReserve]);

    useEffect(() => {
        if (dailydrop.isReserveActive) {
            setIsMysteryReserveActive(true);
        }
        if (dailydrop.isMintActive) {
            setIsMysteryMintActive(true);
        }
        if (dailydrop.whitelist && dailydrop.whitelist.length > 0) {
            if (dailydrop.whitelist.includes(toChecksumAddress(account))) {
                setIsWhitelisted(true);
            }
        }
        if (dailydrop.remainingForReserve) {
            const remForReserve = Number(Moralis.Units.FromWei(dailydrop.remainingForReserve, 0));
            setRemainingForReserve(remForReserve);
        }
    }, [dailydrop]);


    useEffect(() => {
        if (mysteryBox.totalMinted) {
            const totalMinted = Number(Moralis.Units.FromWei(mysteryBox.totalMinted, 0)) || 0;
            setTotalMinted(totalMinted);
        }
    }, [mysteryBox.totalMinted]);


    useEffect(() => {
        if (mysteryBox.allowance) {
            const allowanceNum = Moralis.Units.FromWei(mysteryBox.allowance);
            setAllowanceNumber(allowanceNum);
        }
    }, [mysteryBox.allowance]);

    function approveMaalToken() {
        setIsApproving(true);
        approveMaal(
            {
                onSuccess: async (txn) => {
                    const transaction = await txn.wait();
                    setAllowanceNumber(100000000);
                    setIsApproving(false);
                    getMaalAllowanceReserve();
                    // get Maal balance
                    toast('success', t('success_approval_msg'));
                },
                onError: (error) => {
                    console.log("on error", error);
                    setIsApproving(false);
                }
            });
    }

    const handleSubmit = () => {
        // ToDo: Mint maal API call
        if (config.dailydropmode === 'mint') {
            setIsMinting(true);
            mintMerkel(
                {
                    onSuccess: async (txn) => {
                        const transaction = await txn.wait();
                        setIsMinting(false);
                        getTotalMinted();
                    },
                    onError: (error) => {
                        console.log(error);
                        if (error.message && error.message.includes('Address already claimed')) {
                            toast('error', 'You have already minted. Please wait for the reveal!');
                        } else if (error.message && error.message.includes('Invalid Merkle Proof')) {
                            toast('error', 'You are not whitelisted!');
                        }
                        setIsMinting(false);
                    }
                }
            );
        } else {
            setIsReserving(true);
            reserveSpot(
                {
                    onSuccess: async (txn) => {
                        const transaction = await txn.wait();
                        setIsReserving(false);
                        getWhitelistForDailyDrop();
                        getMaalBalance();
                    },
                    onError: (error) => {
                        console.log(error);
                        if (error.message && error.message.includes('You can only reserve 1 spot')) {
                            toast('error', 'You have already reserved!');
                        }
                        setIsMinting(false);
                    }
                }
            );
        }

    };

    if (isBinanceNetwork && remainingForReserve == 0) {
        return (
            <div className="greyscheme">
                <StyledHeader theme={theme} />
                <section className="jumbotron no-bg container">
                    <div style={{ height: '70vh', flexDirection: 'column', animation: "zoom-in-zoom-out 2s ease infinite" }} className="centered">
                        <div style={{ color: "#ffff" }}>
                            <Image
                                preview={false} src={'https://c.tenor.com/L2Uw7knUbn0AAAAC/sold-out-nope.gif'} />
                            <div className='mt30'>
                                {t('reservation_complete')}
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        )
    }

    return (
        <div className="greyscheme">
            <StyledHeader theme={theme} />
            <section className="jumbotron no-bg container">
                <div>
                    <div>
                        <div className="de-flex justify-content-center mb30">
                            <h3>{t('mystery_box')}</h3>
                        </div>
                    </div>
                </div>
                {
                    (dailydrop.isLoadReserveActive || dailydrop.isLoadReserveActive) &&
                    <>
                        <div style={{ height: '70vh', flexDirection: 'column', animation: "zoom-in-zoom-out 2s ease infinite" }} className="centered">
                            <Spin size="large" />
                            <div style={{ color: "#ffff" }}>
                                Loading...
                            </div>
                        </div>
                    </>
                }

                {
                    isMinting && config.dailydropmode === 'mint' &&
                    <>
                        <div style={{ height: '70vh', flexDirection: 'column', animation: "zoom-in-zoom-out 2s ease infinite" }} className="centered">
                            <Spin size="large" />
                            <div style={{ color: "#ffff" }}>
                                Minting in progress
                            </div>
                        </div>
                    </>
                }


                {isReserving && config.dailydropmode === 'reserve' &&
                    <>
                        <div style={{ height: '70vh', flexDirection: 'column', animation: "zoom-in-zoom-out 2s ease infinite" }} className="centered">
                            <Spin size="large" />
                            <div style={{ color: "#ffff" }}>
                                Reserving in progress
                            </div>
                        </div>
                    </>
                }

                {
                    isMysteryMintActive && !isMinting && config.dailydropmode === 'mint' &&
                    <div className="row justify-content-center">

                        <div className="col-lg-6 col-sm-6 col-xs-12 de-flex justify-content-center">
                            <Image width={400}
                                preview={false} src={'https://ik.imagekit.io/3sqrfownpdc4/magic_box_toh1_-6GMZQ.png?ik-sdk-version=javascript-1.4.3&updatedAt=1646433508826'} />
                        </div>
                        <div className="col-lg-6 col-sm-6 col-xs-12">
                            <div className="mb30" style={{ marginTop: "50px" }}>
                                <div className="de-flex" style={{ flexDirection: 'column' }}>

                                    <div>
                                        {config.dailydrop[config.dailydropmode].description}
                                    </div>
                                    <br />
                                    <div >
                                        {t('mint_price')}
                                        <h5 className="d-balance" style={{ color: 'gold' }}>
                                            {mintPrice} Eth + ${t('gas')}
                                        </h5>
                                    </div>
                                </div>
                            </div>
                            {
                                totalMinted === 0 && hasValidMerkleProof &&
                                <>
                                    <div className='mb30'>
                                        Congrations! You are whitelisted.
                                    </div>
                                    <form id="form-create-item" className="form-border" action="#">
                                        <div className="field-set mb30">
                                            <input
                                                type="button"
                                                onClick={handleSubmit}

                                                id="submit"
                                                className="btn-main"
                                                value={t('mint')}
                                            />
                                        </div>
                                    </form>
                                </>

                            }
                            {totalMinted > 0 && hasValidMerkleProof &&
                                <div>
                                    Congrations! You have already minted. Please wait for the reaveal.
                                </div>
                            }
                            {
                                !hasValidMerkleProof &&
                                <div>
                                    Sorry! You are not whitelisted!
                                </div>

                            }
                        </div>
                    </div>
                }

                {
                    isBinanceNetwork && isMysteryReserveActive && !isReserving && config.dailydropmode === 'reserve' &&
                    <div className="row justify-content-center">

                        <div className="col-lg-6 col-sm-6 col-xs-12 de-flex justify-content-center">
                            <Image width={500}
                                preview={false} src={'https://media.istockphoto.com/photos/noir-movie-character-picture-id837345268?k=20&m=837345268&s=612x612&w=0&h=1tahuBSTIUCUbVcZhaxHMV5iLm-W1c_UBlz7VBAcNrc='} />
                        </div>
                        <div className="col-lg-6 col-sm-6 col-xs-12">
                            <div className="mb30">
                                <div className="de-flex" style={{ flexDirection: 'column' }}>

                                    <div>
                                        {config.dailydrop[config.dailydropmode].description}
                                    </div>
                                    <br />
                                    <div >
                                        {t('reserve_price')}
                                        <h5 className="d-balance" style={{ color: 'gold' }}>
                                            {reservePrice} Maal + ${t('gas')}
                                        </h5>
                                    </div>
                                </div>
                            </div>
                            {!isWhitelisted && allowanceNumber > 0 && !isApproving &&
                                <form id="form-create-item" className="form-border" action="#">
                                    <div className="field-set mb30">
                                        <input
                                            type="button"
                                            onClick={handleSubmit}

                                            id="submit"
                                            className="btn-main"
                                            value={t('reserve')}
                                        />
                                    </div>
                                </form>
                            }
                            {!isWhitelisted && allowanceNumber <= 0 && !isApproving &&
                                <>
                                    <div >
                                        <div className="connect-wal" style={{ flexDirection: 'column' }}>
                                            <div className="mb10">
                                                {t('please_approve_maal_for_reserve')}
                                            </div>
                                            <a>
                                                <span onClick={() => approveMaalToken()}>
                                                    {t('approve_maal')}
                                                </span>
                                            </a>
                                        </div>
                                    </div>

                                </>
                            }
                            {!isWhitelisted && allowanceNumber <= 0 && isApproving &&
                                <>
                                    <div>
                                        <Spin size="large" />
                                        <span style={{ marginLeft: "5px", color: "#ffff" }}>
                                            Approving $MAAL
                                        </span>
                                    </div>

                                </>
                            }
                            {
                                isWhitelisted &&
                                <div>
                                    Congratulations! You are already whitelisted. Mystery drop minting will be happening in the ethereum network. Please wait for the announcement!
                                </div>
                            }

                        </div>
                    </div>
                }


                {
                    isEthNetwork && config.dailydropmode === 'mint' && !isMysteryMintActive &&
                    <div style={{ height: '70vh' }} className="centered">
                        <div className="alert alert-warning" role="alert">
                            Mystery Star Drop mint is not active!
                        </div>
                    </div>
                }

                {
                    isBinanceNetwork && config.dailydropmode === 'mint' && !isMysteryMintActive &&
                    <div style={{ height: '30vh' }} className="centered">
                        <div className="connect-wal de-flex centered" style={{ flexDirection: 'column' }}>
                            <div className="mb30">
                                {t('mint_in_eth_msg')}
                            </div>
                            <a>
                                <span onClick={() => switchNetwork(config[config.network].chainIdEth)}>
                                    {'Switch to Ethereum Network'}
                                </span>
                            </a>
                        </div>
                    </div>
                }

                {
                    isBinanceNetwork && config.dailydropmode === 'reserve' && !isMysteryReserveActive &&
                    <div style={{ height: '70vh' }} className="centered">
                        <div className="alert alert-warning" role="alert">
                            {t('reserve_not_active_msg')}
                        </div>
                    </div>
                }
                {
                    !(dailydrop.isLoadReserveActive || dailydrop.isLoadReserveActive) &&
                    !isBinanceNetwork && config.dailydropmode === 'reserve' &&

                    <div className="connect-wal de-flex centered" style={{ flexDirection: 'column' }}>
                        <div className="mb30">
                            {t('get_whitelisted_msg')}
                        </div>
                        <a>
                            <span onClick={() => switchNetwork(config[config.network].chainId)}>
                                {'Switch to Binance Network'}
                            </span>
                        </a>
                    </div>
                }

            </section>
        </div>
    );



};

export default MysteryBoxDrop;
