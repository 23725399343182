import { useEffect, useState } from "react";
import { Menu, Dropdown, Button } from "antd";
import { DownOutlined } from "@ant-design/icons";
import { AvaxLogo, PolygonLogo, BSCLogo, ETHLogo } from "./Logos";
import { useChain, useMoralis } from "react-moralis";
import config from '../../../config/config';

const styles = {
  item: {
    display: "flex",
    alignItems: "center",
    padding: "0 10px",
  },
  button: {
    borderRadius: "12px",
  },
  chain: {
    color: "#fff",
    border: "1px solid white",
    borderRadius: "10px",
    padding: "2px 10px"
  }
};

const menuItems = [
  {
    key: config.network === 'testnet' ? "0x5" : "0x1",
    value: "ETH",
    icon: <ETHLogo />
  },
  {
    key: config.network === 'testnet' ? "0x61" : "0x38",
    value: "BNB",
    icon: <BSCLogo />
  }
];

function Chains() {
  const { switchNetwork, chainId, chain } = useChain();
  const { isAuthenticated } = useMoralis();
  const [selected, setSelected] = useState({});

  console.log("chain", chain);

  useEffect(() => {
    if (!chainId) return null;
    const newSelected = menuItems.find((item) => item.key === chainId);
    setSelected(newSelected);
    console.log("current chainId: ", chainId);
  }, [chainId]);

  const handleMenuClick = (e) => {
    console.log("switch to: ", e.key);
    // set network here

    switchNetwork(e.key);
  };

  const menu = (
    <Menu onClick={handleMenuClick}>
      {menuItems.map((item) => (
        <Menu.Item key={item.key} icon={item.icon} style={styles.item}>
          <span style={{ marginLeft: "5px" }}>{item.value}</span>
        </Menu.Item>
      ))}
    </Menu>
  );

  if (!chainId || !isAuthenticated) return null;

  return (
    <div>
      <Dropdown overlay={menu} trigger={["click"]}>
        <div style={{ ...styles.chain }}>
          {selected?.icon}
          <span style={{ padding: "0 5px" }}>{selected?.value}</span>
          <DownOutlined />
        </div>
      </Dropdown>
    </div>
  );
}

export default Chains;
