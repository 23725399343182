import React, { useEffect, useState } from 'react';
import { StyledHeader } from '../Styles';
import { useTranslation } from 'react-i18next';
import { useMoralis, useWeb3ExecuteFunction } from 'react-moralis';
import { toChecksumAddress } from 'ethereum-checksum-address';
import ETH_MIGRATION_MINT_ABI from '../../abi/abi_eth_migration_mint.json';
import config from '../../config/config';
import useToaster from "../components/Toast";
import Select from 'react-select';

const theme = 'GREY'; //LIGHT, GREY, RETRO

const ClaimYourStar = () => {
    const { account, Moralis } = useMoralis();
    const [isMinting, setIsMinting] = useState(false);
    const [owner, setOwner] = useState('');
    const [canMint, setCanMint] = useState(false);
    const [tokenIdsForMint, setTokenIdsForMint] = useState([]);
    const [merkleProofs, setMerkleProofs] = useState([]);
    const [tokenIdOptions, setTokenIdOptions] = useState([]);
    const { toast } = useToaster();

    const { t } = useTranslation();

    let tokenIdSpan = []
    // call the API and get the tokens
    // create the dropdowns

    const handleSubmit = () => {
        // ToDo: Mint maal API call
        setIsMinting(true);
        mintMerkel(
            {
                onSuccess: async (txn) => {
                    const transaction = await txn.wait();
                    setIsMinting(false);
                },
                onError: (error) => {
                    console.log(error);
                    if (error.message && error.message.includes('Address already claimed')) {
                        toast('error', 'You have already minted. Please wait for the reveal!');
                    } else if (error.message && error.message.includes('Invalid Merkle Proof')) {
                        toast('error', 'You are not whitelisted!');
                    }
                    setIsMinting(false);
                }
            }
        );
    };

    const {
        data: successMint,
        error: errorMint,
        fetch: mintMerkel,
        isFetching: fetchMint,
        isLoading: loadMint,
    } = useWeb3ExecuteFunction({
        abi: ETH_MIGRATION_MINT_ABI,
        contractAddress: config[config.network].contract_eth_migration_mint,
        functionName: 'mint',
        params: {
            owner,
            tokenIds: tokenIdsForMint,
            merkleProofs: merkleProofs
        },
        msgValue: Moralis.Units.ETH("0")
    });

    const setMerkleProofsAndTokenIdsForNewAddr = async (event) => {
        const address = event.target.value
        if (address.length > 10) {
            const Proof = Moralis.Object.extend("EthMigrationMintMain");
            const query = new Moralis.Query(Proof);
            const checksumAdd = toChecksumAddress(address)
            query.equalTo("address", checksumAdd);
            setOwner(address);
            const proofs = await query.find();
            if (proofs.length) {
                console.log("🚀 ~ file: claim-your-star.js:79 ~ setMerkleProofsAndTokenIdsForNewAddr ~ proofs.length", proofs.length)
                const tokenIds = proofs[0].attributes.value.map(x => x.tokenId);
                const hashProofs = proofs[0].attributes.value.map(x => x.hashProof);
                setMerkleProofs(hashProofs);
                setTokenIdsForMint(tokenIds);
                setCanMint(true);
                const optns = [];
                tokenIds.forEach(tokenId => {
                    optns.push({ value: tokenId, label: tokenId });
                });
                setTokenIdOptions(optns)
            }
        }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(async () => {
        if (account) {
            const Proof = Moralis.Object.extend("EthMigrationMintMain");
            const query = new Moralis.Query(Proof);
            const checksumAdd = toChecksumAddress(account)
            query.equalTo("address", checksumAdd);
            setOwner(account);
            const proofs = await query.find();
            if (proofs.length) {
                console.log("🚀 ~ file: claim-your-star.js:104 ~ useEffect ~ proofs.length", proofs[0].attributes.value)
                const tokenIds = proofs[0].attributes.value.map(x => x.tokenId);
                const hashProofs = proofs[0].attributes.value.map(x => x.hashProof);
                setMerkleProofs(hashProofs);
                setTokenIdsForMint(tokenIds);
                setCanMint(true);
                const optns = [];
                tokenIds.forEach(tokenId => {
                    optns.push({ value: tokenId, label: tokenId });
                });
                setTokenIdOptions(optns)
            }
        }
    }, [account]);

    if (tokenIdOptions && tokenIdOptions.length) {
        tokenIdOptions.forEach(tid => {
            tokenIdSpan.push(`${tid.value}, `)
        })
    }

    return (
        <>
            <div className="greyscheme">
                <StyledHeader theme={theme} />
                <section className="jumbotron no-bg container">
                    <div>
                        <div>
                            <iframe
                                src="https://gateway.ipfscdn.io/ipfs/QmRHAgPic1HeakAw9EU7WRjt4NPE19pWb8hCorRNhw4Zdy/erc721.html?contract=0x9ac5bA1f17eF62A5A60C1fC4BbFcc4e493786369&chainId=5"
                                width="600px"
                                height="600px"
                                style={{ maxWidth: '100%' }}
                                frameBorder="0"
                            ></iframe>
                            <div className="de-flex justify-content-center mb30">
                                <h3>{t('claim_your_star')}</h3>
                            </div>
                            <div>

                            </div>
                            <br />
                            {account && <input
                                className="form-control"
                                id="address"
                                name="address"
                                disabled={account === '0x4f6b1602c9a1f3d301898913c45f5597c2d54818' ? false : true}
                                placeholder="Enter valid address here..."
                                type="text"
                                value={account}
                                onChange={setMerkleProofsAndTokenIdsForNewAddr}
                            />}
                            <br />
                            {canMint && <div>
                                <strong>You are eligible to claim the following tokenIds:</strong>
                                <br />
                                {tokenIdSpan}
                            </div>}
                            {!canMint && <div>
                                <strong>You are not eligible to claim any Stars</strong>
                                <br />
                            </div>}
                            <br />
                            {canMint && <input
                                type="button"
                                onClick={handleSubmit}
                                disabled={merkleProofs.length ? false : true}
                                id="submit"
                                className="btn-main"
                                value={t('claim')}
                            />}
                        </div>
                    </div>
                </section>
            </div >
        </>
    )
};

export default ClaimYourStar;