import React from 'react';
import { Router, Location, Redirect } from '@reach/router';
import ScrollToTopBtn from './menu/ScrollToTop';
import Header from './menu/header';
// import Home from './pages/home';
import Home from './pages/home';
import Mint from './pages/mint';
import MintAStar from './pages/mint-a-star';
import MintMalamaal from './pages/mint-malamaal';
import Redeem from './pages/redeem';
import { useMoralis } from 'react-moralis';
import { createGlobalStyle } from 'styled-components';
import MyStars from './pages/my-stars';
import MyMysteryBox from './pages/my-mystery-box';
import MysteryBoxDrop from './pages/mystery-box-drop';
import ClaimYourStar from './pages/claim-your-star';

const GlobalStyles = createGlobalStyle`
  :root {
    scroll-behavior: unset;
  }
`;

export const ScrollTop = ({ children, location }) => {
  React.useEffect(() => window.scrollTo(0, 0), [location]);
  return children;
};

const PosedRouter = ({ children }) => (
  <Location>
    {({ location }) => (
      <div id="routerhang">
        <div key={location.key}>
          <Router location={location}>{children}</Router>
        </div>
      </div>
    )}
  </Location>
);

const App = () => {
  const { isWeb3Enabled, enableWeb3, isAuthenticated, isWeb3EnableLoading } =
    useMoralis();

  return (
    <div className="wraper">
      <GlobalStyles />
      <Header />
      <PosedRouter>
        <ScrollTop path="/">
          <Home exact path="/">
            <Redirect to="/home" />
          </Home>
          {isAuthenticated && (
            <>
              <Mint path="/mint" />
              <MintAStar path="mint-a-star" />
              <MintMalamaal path="mint-malamaal" />
              <Redeem path="/redeem" />
              <MyStars path="/my-stars" />
              <MyMysteryBox path="/my-mystery-box" />
              <MysteryBoxDrop path="/mystery-box-drop" />
              <ClaimYourStar path="/claim-your-star" />
            </>
          )}
        </ScrollTop>
      </PosedRouter>
      <ScrollToTopBtn />
    </div>
  );
};
export default App;
